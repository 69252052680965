import React from "react";
import { graphql } from "gatsby";
import LayoutPatient from "components/layout/patient";
import HeaderPatientTemplates from "components/section/headerPatientTemplates";
import { Row, Col, Grid, Timeline } from "rsuite";
import SectionWrapper from "../../components/section/sectionWrapper";
import Markdown from "markdown-to-jsx";
import { Tooltip, Alert } from "antd";

const ContentfulPageWhatIs = ({ data }) => {
  return (
    <LayoutPatient>
      <HeaderPatientTemplates
        title={data.contentfulPageWhatIs.title}
        tag="Algemene informatie"
      />
      <SectionWrapper padded background>
        <Grid>
          <Row style={{ fontSize: "18px" }}>
            <Col className="markdown">
              <Markdown
                children={data.contentfulPageWhatIs.mdxText.mdxText}
                options={{
                  overrides: {
                    Alert: {
                      component: Alert,
                    },
                    Tooltip: {
                      component: Tooltip,
                    },
                  },
                }}
              />
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
      <SectionWrapper padded>
        <Grid>
          <Row>
            <Col>
              <Timeline>
                <Timeline.Item>
                  Laatste wijziging: {data.contentfulPageWhatIs.updatedAt}
                </Timeline.Item>
              </Timeline>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
    </LayoutPatient>
  );
};

export const data = graphql`
  query pageQuery($id: String) {
    contentfulPageWhatIs(id: { eq: $id }) {
      url
      title
      id
      description
      updatedAt(formatString: "DD MMMM YYYY", locale: "nl")
      mdxText {
        mdxText
      }
    }
  }
`;

export default ContentfulPageWhatIs;
